body {
  margin: 0;
  font-family: "Noto Sans", "Segoe UI", "Helvetica Neue", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100vw;
  height: 100vh;
  overflow: hidden !important;
}

#root {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.leaflet-container {
  height: 100%;
  width: 100%;
}

input[type="file"] {
  padding: 6px;
  border: solid 1px rgb(96, 94, 92);
  border-radius: 0px !important;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 0.2rem 0.4rem -0.075rem;
}

input[type="file"]:hover {
  padding: 6px;
  border-color: rgb(96, 94, 92);
  border-radius: 0px !important;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 0.2rem 0.4rem -0.075rem;
}

.ui-input__input {
  padding-right: 5px !important;
}

.ui-checkbox__label {
  padding-top: 2px;
}

.ui-dialog {
  border-radius: 0px;
  max-width: calc(100vw - 8px);
  margin: 4px;
  width: calc(100vw - 40px) !important;
  max-width: 900px !important;
}

.ui-menu__item__submenu__submenu {
  max-height: 400px;
  max-width: 100vw;
  width: 400px;
  overflow: auto;
}

.ui-dialog__content {
  overflow-y: scroll;
  max-height: calc(100vh - 200px);
}

BUTTON {
  border-radius: 0px !important;
}
.ui-dropdown__item {
  padding-left: 4px !important;
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}

.ui-dropdown__selecteditem {
  border-radius: 0px !important;
  padding-left: 2px !important;
  margin-top: 4px !important;
  height: 24px !important;
  max-width: 12rem !important;
}

.ui-dropdown__selecteditem__image {
  border-radius: 0px !important;
  max-height: 24px;
}

.ui-dropdown__item__image {
  max-height: 24px;
  max-width: 24px;
  margin-right: 10px;
  margin-bottom: 4px;
  border-radius: 0px !important;
}

.ui-dropdown__selected-items {
  padding-bottom: 0px !important;
}

.ui-dropdown__selecteditem__header {
  max-height: 24px;
  padding-top: 2px !important;
  margin-bottom: 4px;
  padding-left: 3px !important;
}

@media screen and (prefers-contrast: more) {
  .ui-splitbutton__toggle {
    border: solid 1px !important;
  }
}
