.sarr-outer {
  max-width: 570px;
  margin-top: 2px !important;
}

.sarr-add BUTTON {
  padding-left: 3px;
  padding-right: 3px;
  margin-top: 4px;
  margin-bottom: 6px;
  min-width: 30px;
  width: 30px;
}

.sarr-inner {
  display: grid;
}

.sarr-inner .ui-dropdown {
  display: inherit !important;
}

.sarr-inner .ui-dropdown__container {
  width: inherit !important;
}

.sarr-input {
  grid-column: 1;
}

.sarr-input .ui-box {
  min-width: 500px;
}

.sarr-input INPUT {
  padding-left: 6px !important;
  padding-right: 6px !important;
  min-width: 500px;
  margin-bottom: 2px;
}

.sarr-none {
  padding-bottom: 30px;
}
