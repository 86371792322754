.mifi-outer {
  display: table;
  margin-top: 2px !important;
}

.mifi-inner {
  display: table-row;
}

.mifi-cell {
  display: table-cell;
}

.mifi-input {
  width: 60px;
  max-width: 60px;
}

.mifi-input input {
  min-width: 55px;
  width: 52px;
  padding-left: 6px !important;
  padding-right: 6px !important;
}

.mificl-choice {
  cursor: pointer;
}

.mificl-descript {
  max-width: 200px;
  font-size: x-small;
  padding-top: 4px;
}
