.mificl-outer {
  display: block;
  width: 100%;
  margin-top: 2px !important;
}

.mificl-inner {
  display: flex;
}

.mificl-cell {
  display: inline-block;
  padding-right: 4px;
}

.mificl-inner .ui-dropdown__container {
  width: 160px;
}

.mificl-input .ui-form__input {
  padding-top: 0px !important;
}

.mificl-input {
  padding-top: 0px !important;
  width: 60px;
  max-width: 60px;
}

.mificl-input input {
  min-width: 55px;
  width: 52px;
  padding-left: 6px !important;
  padding-right: 6px !important;
}

.mificl-test .ui-dropdown__container {
  width: 205px;
}
