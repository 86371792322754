.df-formArea .ms {
  margin-bottom: 0px !important;
}

.df-form {
  border-top: solid 1px;
  border-bottom: solid 1px;
}

.df-form TEXTAREA {
  min-width: 320px;
  max-width: calc(100vw - 30px);
  min-height: 100px;
  line-height: normal;
  padding: 5px;
  padding-left: 8px;
}

.df-outer {
  padding: 0px;
  vertical-align: top;
  width: 100%;
}

.df-wrapper {
  margin: 6px 8px 0px 8px;
}

.df-headerOuter {
  display: grid;
  grid-template-columns: 1fr 30px;
}

.df-closeArea {
  grid-column: 2;
}

.df-headerTitle {
  font-size: 14pt;
  grid-column: 1;
}

.df-headerSubTitle {
  font-size: 12pt;
  grid-column: 1;
}

.df-subHeaderTitle {
  font-size: 12pt;
  font-weight: bold;
  padding-top: 2px;
  padding-bottom: 2px;
  grid-column: 1;
}

.df-subHeaderSubTitle {
  font-size: 10pt;
  grid-column: 1;
}

.df-fieldDescription {
  font-size: small;
  padding-bottom: 3px;
  padding-left: 14px;
  margin-left: 1px;
  margin-top: 9px;
  margin-bottom: 2px;
}

.df-fieldWrap .ui-checkbox__label {
  font-size: medium;
  padding-top: 0px;
  position: relative;
  color: inherit !important;
  top: -3px;
}

.df-fieldTitle {
  padding-bottom: 0px !important;
  margin-bottom: 3px !important;
}

.df-fieldWrap {
  padding-bottom: 20px;
  padding-top: 14px;
  border-top: solid 1px;
  border-bottom: solid 1px;
}

.df-fieldWrap .ui-form__label {
  padding-bottom: 4px;
  vertical-align: bottom;
  padding-right: 14px;
  display: inline-block !important;
  min-width: 189px;
}

.df-fieldWrap .ui-dropdown {
  display: inline-block;
}

.df-fieldWrap .ui-dropdown__container {
  width: 235px;
}

.df-elementBinTitle {
  font-size: medium;
}

.df-fieldWrap label {
  font-size: medium;
}

.df-cardWrapper {
  padding: 5px 10px 10px 10px;
  border: outset 1px black;
  margin-bottom: 4px;
  margin-top: 4px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 0.2rem 0.4rem -0.075rem;
}

.df-arrayOfKeyedStringSet {
  padding: 5px 10px 5px 10px;
  border: outset 1px black;
  margin-bottom: 4px;
  margin-top: 4px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 0.2rem 0.4rem -0.075rem;
}

.df-description {
  font-size: 10pt;
  padding-top: 2px;
  padding-bottom: 18px;
}

.df-sliderSet {
  display: grid;
  margin-top: 6px;
  grid-template-columns: 180px 80px;
}

.df-sliderSet .ui-form__input {
  margin-left: 10px;
}

.df-elementTitle {
  padding-bottom: 3px;
  font-size: medium;
}

.df-elementBinNoScroll {
  padding: 6px 8px 6px 8px;
  margin-left: 10px;
  min-height: 121px;
  border: inset 1px;
}

.df-elementBin {
  padding: 6px 8px 6px 8px;
  margin-left: 10px;
  min-height: 121px;
  border: inset 1px;
  max-height: calc(100vh - 300px);
  overflow-y: auto;
}

.df-stringArray {
  display: grid;
  grid-template-columns: 115px 1fr;
}

.df-stringArrayTitle {
  grid-column: 1;
  padding-top: 6px;
}

.df-stringArrayData {
  grid-column: 2;
}

.df-stringArrayData .ui-form__input {
  width: 300px;
  max-width: 300px;
  margin-bottom: 1px;
}

.df-elementTitleInvalid {
  color: red;
}

.df-sliderTitle {
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row: 1;
  font-size: medium;
  padding-top: 3px;
  padding-bottom: 3px;
}

.df-sliderInput {
  grid-column: 2;
  grid-row: 2;
  padding-top: 3px;
  padding-bottom: 3px;
}

.df-slider {
  grid-column: 1;
  grid-row: 2;
  padding: 3px;
  padding-left: 10px;
  padding-top: 11px;
}

.df-ro-row {
  display: table-row;
}

.df-ro-title {
  display: table-cell;
  padding-left: 1px;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-right: 8px;
  font-weight: bold;
}

.df-ro-value {
  display: table-cell;
  padding: 4px;
}

.df-sampleTable {
  display: table;
  padding-left: 14px;
  font-size: small;
}

.df-defaultValueDescription {
  padding-top: 10px;
  padding-left: 14px;
  font-size: small;
  font-style: italic;
}

.df-sampleDescription {
  font-style: italic;
  padding-top: 10px;
  padding-left: 14px;
  font-size: small;
}

.df-sampleRow {
  display: table-row;
}

.df-ro-sampleValue {
  display: table-cell;
  padding: 4px;
  font-style: italic;
}

.df-ro-table {
  display: table;
}

.df-keyedBooleanCollection {
  display: grid;
}

.df-keyedBooleanCollectionTitle {
  grid-column: 1;
}

.df-keyedBooleanCollectionData {
  grid-column: 2;
}

.df-keyedBooleanCollectionClose {
  grid-column: 3;
}

.df-keyedStringCollection {
  display: grid;
  grid-template-columns: 205px 1fr 100px;
  max-width: 830px;
}

.df-keyedStringCollectionTitle {
  grid-column: 1;
}

.df-keyedStringCollectionData {
  grid-column: 2;
}

.df-keyedStringCollectionData input {
  width: 500px;
  max-width: calc(100vw - 550px);
  margin-bottom: 1px;
}

.df-keyedStringCollectionClose {
  grid-column: 3;
}

.df-keyedStringCollectionClose button {
  height: 30px;
}

.df-fieldWrapNumber input {
  max-width: 72px;
}
