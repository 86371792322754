.setna-mainArea {
  padding-top: 20px;
  display: grid;
  grid-template-columns: 200px 1fr;
  padding-bottom: 20px;
}

.setna-label {
  grid-column: 1;
}

.setna-name {
  grid-column: 2;
  padding: 8px;
}
