.bte-area {
  width: calc(100% - 2px);
  min-width: calc(100% - 2px);
  max-width: calc(100% - 2px);
}

.bte-headerTransition {
  background-position-x: left;
  background-repeat: repeat-x;
  height: 40px;
}

.bte-header {
  font-size: 16pt;
  padding-top: 10px;
  padding-left: 15px;
  padding-bottom: 15px;
}

.bte-componentHeader {
  padding-top: 20px;
  font-size: 14pt;
  padding: 12px;
  padding-top: 40px;
  padding-bottom: 14px;
}

.bte-extraArea {
  margin-bottom: 5px;
}

.bte-commands {
  display: grid;
  padding: 3px;
  grid-template-columns: 200px 1fr;
}

.bte-toolBarArea {
  padding-bottom: 0px;
  height: 31px;
}

.bte-extraArea {
  grid-column: 2;
}
