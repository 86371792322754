@media only screen and (min-width: 1316px) {
  .home-search-area {
    grid-column: 2;
    padding-top: 14px;
    padding-bottom: 16px;
  }
  .home-projects-note {
    margin-bottom: 13px;
  }
}

@media only screen and (max-width: 1316px) and (min-width: 800px) {
  .home-projects-note {
    margin-bottom: 13px;
  }
}

@media only screen and (min-width: 800px) {
  .home-layout {
    width: 100%;
    display: grid;
    grid-template-rows: 112px 0.5fr 0.5fr 60px;
  }

  .home-main {
    grid-template-columns: 400px 1fr;
    display: grid;
  }

  .home-header {
    font-size: 30pt;
    height: 100%;
    border-bottom: outset 2px;
  }

  .home-toolTile {
    height: 130px;
    margin-top: 10px;
    margin-left: 10px;
    width: 310px;

    display: inline-flex;
    text-align: left;
    vertical-align: middle;
  }

  .home-header-area {
    border-bottom: inset 2px;
  }

  .home-usage {
    border-top: inset 2px;
  }

  .home-gallery {
    grid-column-start: 2;
    grid-column-end: 3;
  }

  .home-projects-bin {
    min-width: 320px;
    border-right: solid 1px black;
    padding: 10px 10px 10px 7px;
    grid-column-start: 1;
    grid-column-end: 2;
  }

  .home-gallery-interior {
    overflow-y: auto;
    border-left: solid 1px white;
  }

  .home-projects-list {
    overflow-y: scroll;
    margin-top: 8px;
  }

  .home-dialog {
    height: 320px;
    display: grid;
    grid-template-rows: 50px 50px 50px 50px 1fr;
    grid-template-columns: 140px 1fr;
    padding-bottom: 15px;
    padding-top: 20px;
  }

  .home-dialog-large {
    height: 520px;
  }
}

@media only screen and (max-width: 800px) {
  .home-search-area {
    padding-top: 4px;
    padding-left: 32px;
    padding-bottom: 14px;
  }

  .home-toolTile {
    height: 130px;
    display: inline-flex;
    margin-top: 10px;
    margin-left: 6px;
    margin-right: 6px;
    width: 320px;
    text-align: left;
    opacity: 0.8;
    border: solid 1.5px black;
  }

  .home-layout {
    width: 100%;
    height: 100vh;
    overflow-y: auto;
  }

  .home-header {
    height: 120px;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .home-toolTile-bin {
    text-align: center;
    min-height: 160px;
  }

  .home-projects-bin {
    min-width: 320px;
    vertical-align: middle;
  }

  .home-projects-note {
    margin-bottom: 16px;
  }

  .home-projects {
    padding-top: 10px;
    margin-bottom: 4px;
    line-height: 1;
  }

  .home-projects-list {
    padding-bottom: 14px;
  }

  .home-legal {
    padding-bottom: 80px;
  }

  .home-dialog {
    padding-bottom: 15px;
    padding-top: 20px;
  }
}

.home-starterArea {
  padding-top: 10px;
  display: block;
}

.home-search-area {
  padding-top: 12px;
  padding-left: 32px;
  display: inline-block;
}

.home-uploadFile {
  display: inline-block;
  border: 0px !important;
  max-width: calc(100vw - 40px);
  box-shadow: none !important;
}

.home-tools-bin-upload {
  grid-row: 1;
}

.home-inspectFileUpload {
  max-width: calc(100vw - 100px);
  margin-left: 0px !important;
  width: 250px;
}

.home-projects-note {
  padding-left: 10px;
  font-size: x-small;
}

.home-dragOver {
  position: fixed;
  width: calc(100vw - 20px);
  height: calc(100vh - 20px);
  background-color: rgba(48, 96, 48, 0.9);
  border: solid 3px rgba(48, 96, 48, 1);
  vertical-align: middle;
  text-align: center;
  margin: 10px;
  z-index: 10000;
  opacity: 0.9;
}

.home-projects-buttonbar {
  padding-left: 10px;
}

.home-projects {
  padding-left: 10px;
}

.home-header-sublink {
  font-size: small;
  color: white;
}

.home-header-sublink A {
  font-size: small;
  color: white;
}

.home-areaLoading {
  padding: 20px;
}

.home-inlineMessage {
  padding: 20px;
  margin-bottom: 10px;
}

.home-loadingIcon {
  width: 16px;
  position: relative;
  top: 2px;
  margin-right: 6px;
}

.home-header-docsLink {
  color: white;
  padding-left: 6px;
  background-color: transparent;
  border: 0px;
  text-decoration: underline;
  padding-right: 0px;
}

.home-header-textArea {
  padding-left: 6px;
}

.home-error {
  background-color: #b07a14;
  padding: 10px;
  margin: 20px;
  word-break: normal;
  text-overflow: ellipsis;
}

.home-header-area {
  vertical-align: middle;
  text-align: center;
  background-image: url(../images/bg-wool-dark.png);
  grid-row: 1;
  grid-column-start: 1;
  grid-column-end: 3;
}

.home-header-image-outer {
  display: inline-block;
  margin: 0px;
  line-height: 1;
  background-size: cover;
}

.home-header-image {
  width: 266px;
  height: 60px;
}

.home-projects {
  font-size: medium;
  margin-top: 1px;
}

.home-recentItem {
  display: grid;
  grid-template-columns: 244px 80px;
}

.home-recentItemLabel {
  display: block;
  padding-top: 10px;
  padding-bottom: 8px;
  overflow: clip;
  white-space: nowrap;
  max-width: 240px;
  font-size: small;
  grid-column: 1;
}

.home-recentItemModified {
  grid-column: 2;
  padding-top: 10px;
  font-size: small;
  padding-bottom: 8px;
  white-space: nowrap;
}

.home-tools {
  font-size: large;
}

.home-tools-bin-inner {
  padding-top: 1px;
  font-size: small;
  display: grid;
  grid-template-rows: 32px 32px;
}

.home-tools-bin-upload-label {
  padding-left: 7px;
}

.home-tools-bin-inner BUTTON DIV SPAN {
  padding-right: 0px !important;
}

.home-tools-wrap {
  margin-right: 4px;
}

.home-tools-export {
  padding-left: 14px;
}

.home-projects-list .ui-list__itemheader {
  white-space: nowrap;
  max-width: 200px;
  overflow-x: hidden;
  text-overflow: ellipsis;
}

.home-uploadButton {
  margin-bottom: 10px;
}

.home-uploadButton {
  font-size: medium;
}

.home-uploadButton INPUT {
  font-size: small;
}

.home-toolTileInner {
  height: 100%;
  padding: 8px 10px 8px 10px;
}

.home-tileDown {
  border: inset 3px !important;
}

.home-toolTile input[type="file"] {
  box-shadow: none;
  margin-left: 5px;
  font-weight: 600;
  border-color: rgb(72, 73, 74);
}

.home-toolTile-label {
  font-weight: bold;
  padding-bottom: 6px;
  margin-left: 1px;
  margin-top: 0px;
  margin-bottom: 0px;
  display: grid;
  grid-template-columns: 24px 1fr;
}

.home-toolTile-instruction {
  font-size: x-small;
  margin-left: 1px;
  padding-bottom: 8px;
  height: 36px;
}

.home-toolTile-button {
  margin-left: 0px;
  height: 35px !important;
  border: solid 1px rgb(72, 73, 74) !important;
  box-shadow: none !important;
  text-align: center;
  margin-top: 2.5px;
  padding-top: 8px;
  width: 100%;
  vertical-align: middle;
  font-weight: bold;
  padding-left: 8px !important;
  padding-right: 8px !important;
  max-width: calc(100vw - 100px) !important;
}

.home-toolTile-button SPAN {
  max-width: 350px !important;
}

.home-clickLink {
  cursor: pointer;
  text-decoration: underline;
}

.home-uploadLabel {
  padding-top: 10px;
  padding-bottom: 4px;
}

.home-path {
  padding-bottom: 6px;
}

.home-usage {
  width: 100%;
  padding-bottom: 4px;
}

.home-usage-interior {
  padding-left: 13px;
  padding-right: 13px;
  padding-top: 4px;
}

.home-main {
  grid-row-start: 2;
  grid-row-end: 3;
  width: 100%;
}

.home-footer {
  grid-row: 4;
  grid-column-start: 1;
  grid-column-end: 3;
  width: 100%;
  line-height: normal;
}

.home-legal {
  padding-left: 13px;
  padding-right: 13px;
  padding-top: 1px;
}

.home-gallery-label-top {
  padding-top: 30px !important;
}

.home-gallery-label {
  display: inline-block;
  margin-bottom: 4px;
  line-height: 1;
  font-size: large;
  grid-column: 1;
  margin-top: 0px;
  margin-bottom: 0px;
  padding-top: 8px;
  padding-left: 10px;
  padding-bottom: 6px;
  user-select: none;
}
