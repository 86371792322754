.dfe-area {
  width: calc(100% - 2px);
  min-width: calc(100% - 2px);
  max-width: calc(100% - 2px);
  overflow-y: auto;
  display: grid;
}

.dfe-headerTransition {
  background-position-x: left;
  background-repeat: repeat-x;
  height: 40px;
}

.dfe-header {
  font-size: 16pt;
  padding-top: 12px;
  padding-left: 12px;
  padding-bottom: 12px;
  grid-column-start: 1;
  grid-column-end: 3;
}

.dfe-form {
  padding-left: 4px;
  padding-top: 4px;
  grid-column: 1;
  grid-row: 2;
}

.dfe-sourceFormform {
  padding-left: 4px;
  padding-top: 4px;
  grid-column: 2;
  grid-row: 2;
}
